<template>
    <div class="contain">
        <searchBar :searchInput="false"></searchBar>
        <div class="bgf" @click.stop="statesVisible(false)">
            <br />
            <div class="fs_24 center color_333 padding_20 bottom_link">
                {{ title }}
            </div>
            <el-form :model="form" :rules="rules" ref="ruleForm" label-position="top" :hide-required-asterisk="true" class="form">
                <div class="fs_20 color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    <span class="bold mr_5">基本信息</span><span class="sub_color fs_16">(必填)</span>
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="职位名称" prop="job_name">
                            <el-input type="text" @input="handleInput('job_name')" maxlength="20" v-model.trim="form.job_name" placeholder="请填写职位名称"></el-input>
                            <p class="fs_14 color_999 flex_c">
                                <span class="btn mr_10" v-for="(item, index) in hotJob" :key="index" @click="handleValue(item, 'job_name')">{{ item }}</span>
                            </p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="所属部门" prop="dept_name">
                            <el-input type="text" @input="handleInput('dept_name')" v-model.trim="form.dept_name" placeholder="任职部门"></el-input>
                            <p class="fs_14 color_999 flex_c">
                                <span class="btn mr_10" v-for="(item, index) in hotDep" :key="index" @click="handleValue(item, 'dept_name')">{{ item }}</span>
                            </p>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="招聘人数" prop="num">
                            <el-input type="number" v-model.trim="form.num" min="1" placeholder="请填写招聘人数"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" style="position: relative;">
                        <el-form-item label="工作地点" prop="working_place_id">
                            <el-input type="text" v-model.trim="address" @click.stop="statesVisible(true)" readonly placeholder="请添加工作地点"></el-input>
                        </el-form-item>
                        <el-collapse-transition>
                            <div class="states bgf" v-if="showStates" @click.stop="statesVisible(true)">
                                <div class="flex padding_15 bgf fs_14 color_333 item bottom_link" v-for="(item, index) in addressOptions" :key="index">
                                    <p class="pointer flex_1 one" :class="{color:item.id == form.working_place_id}" @click.stop="checkAddress(item)">{{item.curraddress}}</p>
                                    <p class="color pointer" @click.stop="addWorkAddress(item.id)">编辑</p>
                                </div>
                                <div class="btn flex_c add-btn fs_14" @click.stop="addWorkAddress('')"><span class="el-icon-circle-plus-outline fs_18 mr_5"></span>新增地址</div>
                                <div class="flex_cen padding_15 top_link" v-if="addressTotal>addressLimit">
                                    <el-pagination class="page" background :page-size="addressLimit" :current-page="addressPage" layout="prev, pager, next" :total="addressTotal" @current-change="addressLoad"></el-pagination>
                                </div>
                            </div>
                        </el-collapse-transition>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="工作内容" prop="content">
                            <el-input type="textarea" v-model.trim="form.content" :autosize="{ minRows: 6, maxRows: 6 }" resize="both" maxlength="500" placeholder="请详细描述本职位的工作内容"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label=" ">
                            <div class="fs_16 color_666" style="margin-top:-16px">
                                <span class="mr_5">工作现场视频</span><span class="sub_color fs_12">(非必填，最多三条)</span>
                            </div>
                            <el-video-upload @videoIds="getVideoId" ref="videoUpload"></el-video-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="fs_20 color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    <span class="bold mr_5">薪资福利</span><span class="sub_color fs_16">(必填)</span>
                </div>
                <div class="flex_start flex">
                    <el-row class="w_50" :gutter="10">
                        <el-col :span="11">
                            <el-form-item label="综合月薪（元）" prop="salary_min">
                                <el-input v-model.trim="form.salary_min" type="number" maxlength="10" show-word-limit placeholder="请输入数字"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'&nbsp'">
                                <div class="color_666 fs_20 h_44 center">-</div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item class="not_spot" :label="'&nbsp'" prop="salary_max">
                                <el-input v-model.trim="form.salary_max" type="number" maxlength="10" show-word-limit placeholder="请输入数字"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="w_50" :gutter="10">
                        <el-col :span="12">
                            <el-form-item label="发薪日期" prop="payday" class="mr_5">
                                <el-select v-model="form.payday" placeholder="请选择发薪日期">
                                    <el-option v-for="item in grantDay" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="发薪周期" prop="pay_cycle" class="ml_5">
                                <el-select v-model="form.pay_cycle" placeholder="请选择发薪周期">
                                    <el-option v-for="item in grantCircle" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-form-item label="福利" prop="tag_ids">
                    <el-checkbox-group v-model="form.tag_ids" class="gift">
                        <el-row :gutter="20">
                            <template v-for="(item, index) in gift" :key="index">
                                <el-col :span="4">
                                    <div class="gift_item">
                                        <el-checkbox :label="item.id" border>
                                            {{ item.tag_name }}
                                        </el-checkbox>
                                        <!-- <span class="el-icon-circle-close del-btn fs_20"></span> -->
                                    </div>
                                </el-col>
                            </template>
                            <el-col :span="4">
                                <div class="gift_body" v-if="is_gift">
                                    <el-input v-model.trim="add_gift" :maxlength="6" placeholder="请输入6字以内" @input="add_gift=add_gift.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')" type="text" class="add_gift" clearable @keyup="keyupGift" />
                                    <div class="flex gift_button">
                                        <el-button class="flex_1" type="text" @click.stop="closeGift">
                                            取消
                                        </el-button>
                                        <el-button class="flex_1" type="text" @click.stop="postGift">
                                            保存
                                        </el-button>
                                    </div>
                                </div>
                                <el-button plain class="addGift-btn" v-else @click.stop="addGift">
                                    + 添加福利
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-checkbox-group>
                </el-form-item>
                <div class="fs_20 color_333 padding_20 padding_lr_0 bottom_link mb_15">
                    <span class="bold mr_5">任职要求</span><span class="sub_color fs_16">(必填)</span>
                </div>
                <div class="flex">
                    <el-row :gutter="10" class="w_50">
                        <el-col :span="24">
                            <el-form-item label="工作经验" prop="work_exp">
                                <el-select v-model="form.work_exp" placeholder="请选择工作经验">
                                    <el-option v-for="item in Exp" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="10" class="w_50">
                        <el-col :span="11">
                            <el-form-item label="年龄区间" prop="age_min">
                                <el-select v-model="form.age_min" @change="selectAge('start')" placeholder="请选择">
                                    <el-option v-for="item in ageStart" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-form-item :label="'&nbsp'">
                                <div class="color_666 fs_20 h_44 center">-</div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="11">
                            <el-form-item class="not_spot" :label="'&nbsp'" prop="age_max">
                                <el-select v-model="form.age_max" @change="selectAge('end')" placeholder="请选择">
                                    <el-option v-for="item in ageEnd" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <div class="flex">
                    <el-row :gutter="10" class="w_50">
                        <el-col :span="24">
                            <el-form-item label="最低学历要求" prop="education">
                                <el-select v-model="form.education" placeholder="请选择">
                                    <el-option v-for="item in Education" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row class="w_50">
                        <el-col :span="24">
                            <el-form-item label="性别" prop="gender" class="ml_10">
                                <el-select v-model="form.gender" placeholder="请选择性别">
                                    <el-option v-for="item in Gender" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
                <el-form-item label="其他说明">
                    <div class="button_list">
                        <el-button size="mini" v-for="(item, index) in buttonList" :key="index" round class="mb_10" @click="clickButton(index)">
                            {{ item }}<i class="el-icon-plus el-icon--right"></i>
                        </el-button>
                        <el-row class="mt_5">
                            <el-col :span="24">
                                <el-input v-model.trim="form.other_content" type="textarea" resize="none" placeholder="其他说明..." class="explain"></el-input>
                            </el-col>
                        </el-row>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="submit fs_18" type="primary" :disabled="videoUploading" :loading="loading" @click="submitForm('ruleForm')">
                        {{ id ? '提 交' : '发 布' }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <amap ref="amap" @callback="getWorkAdr"></amap>
    </div>
</template>

<script>
import { regionData, CodeToText } from "element-china-area-data"
import { isNumber } from "@/common/validate"
import { education_select, work_exp, gender, circle } from "@/common/base.js"
import amap from './components/amap'
import elVideoUpload from '@/components/elVideoUpload'
export default {
	name: "PositionPost",
	components: { amap, elVideoUpload },
	data() {
		const validateNumber = (rule, value, callback) => {
			if (!isNumber(value)) {
				callback(new Error("请输入正确的数字"))
			} else {
				callback()
			}
		}
		let i = 0,
			num = 0
		const grantDay = []
		for (i = 0; i < 31; i++) {
			num = i + 1
			grantDay[i] = {
				value: num,
				label: num + "号"
			}
		}
		let ageList = []
		for (i = 0; i <= 44; i++) {
			num = i + 16
			ageList[i] = {
				value: num,
				label: num + "岁",
				disabled: false
			}
		}
		ageList = [
			{
				value: 0,
				label: "不限",
				disabled: false
			}
		].concat(ageList)
		//设置开始岁数筛选数组
		var ageStart = JSON.parse(JSON.stringify(ageList))
		//设置结束岁数筛选数组
		var ageEnd = JSON.parse(JSON.stringify(ageList))
		return {
			title: "发布新职位",
			is_gift: false,
			loading: false,
			add_gift: "",
			id: "",
			address: "", //地址
			videoUploading: false,
			form: {
				job_name: "", //职位名称
				dept_name: "", //招聘部门
				num: "", //招聘人数
				working_place_id: '', // 工作地址 id
				content: "", //工作内容
				videos: [],
				salary_min: null, //综合薪资
				salary_max: null,
				payday: null, //发薪日期
				//发薪周期: 'every' => '次结','day' => '日结', 'week' => '周结','month' => '月结'
				pay_cycle: "",
				tag_ids: [], //福利ID
				work_exp: 0, //工作经验
				age_min: 0, //年龄区间
				age_max: 0,
				education: 0, //学历
				gender: 0, //性别
				other_content: "" //其他说明
			},

			showStates: false,
			addressLimit: 5,
			addressPage: 1,
			addressTotal: 0,
			addressOptions: [],

			rules: {
				job_name: [
					{ required: true, message: "请填写职位名称", trigger: [ "blur", "change" ] }
				],
				dept_name: [
					{ required: true, message: "请填写所属部门", trigger: [ "blur", "change" ] }
				],
				content: [
					{ required: true, message: "请填写工作内容", trigger: "blur" },
					{ min: 1, max: 500, message: "内容在500字以内", trigger: "blur" }
				],
				working_place_id: [
					{ required: true, message: "请添加工作地点", trigger: "change" }
				],
				salary_min: [
					{ required: true, message: "请填写月薪", trigger: "blur" },
					{ validator: validateNumber, trigger: "blur" }
				],
				salary_max: [
					{ required: true, message: "请填写月薪", trigger: "blur" },
					{ validator: validateNumber, trigger: "blur" }
				],
				num: [ { required: true, message: "请填写招聘人数", trigger: "blur" } ],
				payday: [
					{ required: true, message: "请选择发薪日期", trigger: "change" }
				],
				pay_cycle: [
					{ required: true, message: "请选择发薪周期", trigger: "change" }
				],
				work_exp: [
					{ required: true, message: "请选择工作经验", trigger: "change" }
				],
				gender: [ { required: true, message: "请选择性别", trigger: "change" } ],
				age_min: [
					{ required: true, message: "请选择年龄时间", trigger: "change" }
				],
				age_max: [
					{ required: true, message: "请选择年龄区间", trigger: "change" }
				],
				education: [
					{ required: true, message: "请选择学历要求", trigger: "change" }
				],
				tag_ids: [ { required: true, message: "请选择福利", trigger: "change" } ]
			},
			grantDay,
			Area: regionData,
			grantCircle: circle,
			Exp: work_exp,
			Gender: gender,
			ageStart,
			ageEnd,
			Education: education_select,
			// 薪资结构 食宿情况 住宿条件 福利说明 到岗时间 文化程度 健康要求 优先条件 工衣情况 交通情况
			buttonList: [
				"薪资结构",
				"食宿情况",
				"住宿条件",
				"福利说明",
				"到岗时间",
				"文化程度",
				"健康要求",
				"优先条件",
				"工衣情况",
				"交通情况"
			],
			gift: [],
			hotDep: [],
			hotJob: []
		}
	},
	created() {
		this.getHot()
		this.getTab()

		this.id = this.$route.query.id || ""
		if (this.id) {
			this.title = "编辑职位"
			this.$route.meta.subtitle = '编辑职位'
			this.getDetail()
		} else {
			this.getWorkAdr()
		}
	},
	beforeRouteLeave(to, from, next) {
		if (this.videoUploading) { // 是否对页面进行了编辑
			this.$confirm("现场视频正在上传中，是否离开当前页面?", "系统提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				next()
			})
			return
		}
		next()
	},
	methods: {
		handleInput(key) {
			this.form[key] = this.form[key].replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
		},
		getHot() {
			this.$axios({
				url: "/ent/v3/job/pre",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.hotDep = res.data.dept_name
					this.hotJob = res.data.job_name
				}
			})
		},
		getTab() {
			this.$axios({
				url: "/ent/v3/job/tag_list",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.gift = this.gift.concat(
						res.data.tag_sys_list,
						res.data.tag_com_list
					)
				}
			})
		},
		getDetail() {
			this.$axios({
				url: `/ent/v3/job/${this.id}`,
				method: "GET"
			}).then(res => {
				if (res.code === 200) {
					let { tag_list, ...data } = res.data.job
					let ids = []
					this.form = data
					// var { province_code, city_code, area_code } = data
					// this.form.pca = [ province_code, city_code, area_code ]
					tag_list.find(item => {
						ids.push(item.tag_id)
					})
					this.form.tag_ids = ids

					let videos = []
					data.videos.forEach(item => {
						videos.push({ url: item.coverUrl, videoId: item.videoId, playAuth: '', videoDisposeTime: 0, id: videos.length + 1 })
					})
					this.$nextTick(() => {
						this.$refs.videoUpload.fileList = videos
						this.$refs.videoUpload.showSuccessTost = false
						this.$refs.videoUpload.getCover()
						this.$refs.videoUpload.queryVideoStatus()
					})

					this.getWorkAdr()
				}
			})
		},
		clickButton(index) {
			// this.form.explain = this.form.explain + this.buttonList[index] + "：\n"
			if (this.form.other_content) {
				this.form.other_content =
                    this.form.other_content + "\n" + this.buttonList[index] + "："
			} else {
				this.form.other_content = this.buttonList[index] + "："
			}
		},
		getVideoId(videos) {
			this.videoFiles = videos
			let videoIds = []
			this.videoUploading = false
			videos.forEach(item => {
				if (!item.playAuth) {
					this.videoUploading = true
				}
				videoIds.push(item.videoId)
			})
			this.form.videos = videoIds
		},
		submitForm(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: "/ent/v3/job" + (this.id ? `/${this.id}` : ""),
						method: this.id ? "PUT" : "POST",
						data: this.form,
						shake: true,
						hideLoading: true
					}).then(res => {
						setTimeout(() => {
							this.loading = false
						}, 900)
						if (res.code === 200) {
							this.$message.success(res.msg)
							setTimeout(() => {
								this.$router.replace("/position")
							}, 1000)
						}
					})
				} else {
					this.$message.warning('请填写完整信息')
				}
			})
		},
		//福利操作逻辑
		addGift() {
			this.is_gift = true
		},
		closeGift() {
			this.is_gift = false
		},
		keyupGift(e) {
			if (e.keyCode === 13) {
				this.postGift()
			}
		},
		postGift() {
			if (this.add_gift != "") {
				this.$axios({
					url: "/ent/v3/job/tag",
					method: "POST",
					data: { tag_name: this.add_gift },
					shake: true
				}).then(res => {
					if (res.code === 200) {
						this.gift = this.gift.concat([ res.data ])
						this.form.tag_ids = this.form.tag_ids.concat([ res.data.id ])
					}
					this.add_gift = ""
					this.closeGift()
				})
			}
		},
		//省市区筛选操作逻辑
		handleChange(value) {
			if (value != undefined) {
				this.form.province = value[0] != "" ? CodeToText[value[0]] : ""
				this.form.city = value[1] != "" ? CodeToText[value[1]] : ""
				this.form.area = value[2] != "" ? CodeToText[value[2]] : ""
				this.form.province_code = value[0] != "" ? value[0] : ""
				this.form.city_code = value[1] != "" ? value[1] : ""
				this.form.area_code = value[2] != "" ? value[2] : ""
			}
		},
		//年龄筛选
		selectAge(type) {
			if (type == "start") {
				var num = 0
				this.ageEnd.forEach((item, index) => {
					if (item.value > this.form.age_min || this.form.age_min == 0) {
						this.ageEnd[index].disabled = false
					} else if (this.form.age_min != 0) {
						this.ageEnd[index].disabled = true
					}
					if (item.value == this.form.age_min) num = index
				})
				if (this.form.age_max <= this.form.age_min) {
					this.form.age_max = this.ageEnd[num + 1].value
				} else if (this.form.age_min == 0) {
					this.form.age_max = 0
				}
			} else {
				this.ageStart.forEach((item, index) => {
					if (item.value < this.form.age_max) {
						this.ageStart[index].disabled = false
					} else {
						this.ageStart[index].disabled = true
					}
				})
			}
		},
		handleValue(v, key) {
			this.form[key] = v
		},
		// 工作地址列表
		getWorkAdr(address) {
			this.$axios({
				url: '/ent/v3/working_place',
				methods: 'GET',
				data: {
					limit: this.addressLimit,
					page: this.addressPage,
					job_id: this.id
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.addressTotal = res.data.total
					res.data.list.forEach(item => {
						item.curraddress = `${item.province}${item.city}${item.district}${item.address}${item.room_number}`
						item.seleted = false
						if (item.is_default == 1) {
							item.seleted = true
							this.address = item.curraddress
							if (!this.id) {
								this.form.working_place_id = item.id
							}
						}
					})

					this.addressOptions = res.data.list
					if (this.id) {
						this.addressOptions.forEach(item => {
							if (item.id === this.form.working_place_id) {
								this.address = item.curraddress
							}
						})
					}

					if (address) { // 选中新增或者编辑后的地址
						this.addressOptions.forEach(item => {
							item.seleted = false
							if (item.address === address) {
								item.seleted = true
								this.address = item.curraddress
								this.form.working_place_id = item.id
							}
						})
					}
				}
			})
		},
		addressLoad(page) {
			this.addressPage = page
			this.getWorkAdr()
		},
		checkAddress(item) {
			this.form.working_place_id = item.id
			this.address = item.curraddress
			this.showStates = false
			this.$forceUpdate()
		},

		statesVisible(v) {
			this.showStates = v
		},
		addWorkAddress(id) {
			this.$refs.amap.mapVisible = true
			this.$refs.amap.id = id
			if (id) {
				this.$refs.amap.getMap()
			} else {
				this.$nextTick(() => {
					this.$refs.amap.initMap()
				})
			}
			this.showStates = false
		}
	}
}
</script>

<style lang="less" scoped>
.states {
    position: absolute;
    width: 1000px;
    height: auto;
    // max-height: 350px;
    right: 10px;
    top: 80px;
    z-index: 200;
    box-shadow: 0px 3px 20px 0 rgba(158, 158, 158, 0.16);

    .item {
        transition: color 0.2s ease;
        .pointer:hover {
            color: #1890ff;
            transition: color 0.2s ease;
        }
    }
}

.color_1890FF {
    color: #1890ff;
}
.ml_5 {
    margin-left: 5px;
}
.ml_10 {
    margin-left: 10px;
}
.h_44 {
    height: 44px;
    line-height: 44px;
}
.w_50 {
    width: 50%;
}
.padding_lr_0 {
    padding-left: 0;
    padding-right: 0;
}
.gift_item {
    position: relative;
    .del-btn {
        position: absolute;
        top: -8px;
        right: -5px;
        z-index: 3;
        color: #f56c6c;
        background: #fff;
        cursor: pointer;
    }
}
.addGift-btn {
    color: #1890ff;
    border-color: #1890ff;
}
.add-btn {
    padding: 0 20px;
    height: 40px;
    &:hover {
        background: #fff;
        color: #1890ff;
    }
}
/deep/.el-dialog__body {
    border-top: 1px solid #ddd;
}
/deep/.addressForm {
    .el-input__inner {
        height: 44px;
        background-color: #f9f9f9;
        border-radius: 0px;
        width: 100% !important;
    }
    .el-form-item__label {
        line-height: 44px;
    }
}
/deep/.form {
    padding: 30px 100px 90px;
    .el-select {
        width: 100%;
    }
    .el-form-item__label {
        padding: 0 !important;
        font-size: 16px;
    }
    .el-input__inner {
        height: 44px;
        background-color: #f9f9f9;
        border-radius: 0px;
        width: 100% !important;
    }

    .explain {
        .el-textarea__inner {
            padding: 20px;
            min-height: 150px !important;
            height: auto;
        }
    }
    .el-textarea__inner {
        height: 44px;
        background-color: #f9f9f9;
        border-radius: 0px;
    }
    .el-range-editor--small {
        .el-range-separator {
            font-size: 20px;
            font-weight: bold;
            line-height: 34px;
            width: 10%;
        }
        .el-range-input {
            font-size: 14px;
            background-color: #f9f9f9;
        }
        .el-range__icon {
            width: 10%;
        }
        .el-range__close-icon {
            width: 10%;
        }
    }
    .el-input {
        .el-input__count {
            .el-input__count-inner {
                background-color: transparent;
            }
        }
    }
    .button_list {
        .el-button {
            transition: all 0.2s ease;
            &:hover {
                color: #5ab0ff;
                border-color: #5ab0ff;
                background-color: transparent;
                transition: all 0.2s ease;
            }
        }
        .el-button--mini {
            padding: 7px 12px;
        }
        .el-button--mini.is-round {
            padding: 7px 12px;
        }
    }
    .submit {
        margin: 0 auto;
        width: 200px;
        line-height: 26px;
        height: 52px;
        display: block;
        margin-top: 50px;
        border-radius: 2px;
    }
    .w_100p {
        width: 100%;
    }
}

/**将点置换到右边*/
/deep/.not_spot .el-form-item__label::before {
    color: #fff !important;
}
/deep/.gift {
    .el-checkbox.is-bordered.el-checkbox--small {
        width: 100%;
        height: 44px;
        margin-bottom: 20px;
        border-radius: 0px;
        .el-checkbox__label {
            text-align: center;
            line-height: 34px;
            width: 100%;
            height: 34px;
            padding-right: 14px;
            font-size: 14px;
        }
    }
    .el-input__inner {
        height: auto;
        background-color: transparent;
        border: none;
    }
    .el-button {
        width: 100%;
        height: 44px;
        margin-bottom: 20px;
        border-radius: 0px;
        span {
            font-size: 14px;
        }
    }
    .gift_body {
        background: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        .add_gift {
            text-align: center;
            margin-right: 10px;
        }
        .el-input__inner {
            text-align: center;
            padding: 5px 10px;
        }
        .gift_button {
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 1px;
                width: 100%;
                background-color: #dcdfe6;
            }
        }
        .el-button {
            width: 100%;
            height: 44px;
            margin: 0 auto;
            &:first-child {
                border-right: 1px solid #dcdfe6;
            }
        }
    }
}
/deep/.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner {
    height: 18px;
    width: 18px;
    // border-radius: 100%;
}
/deep/.el-checkbox.is-bordered.el-checkbox--small .el-checkbox__inner::after {
    width: 5px;
    height: 10px;
    top: 0;
    left: 5px;
}
</style>
