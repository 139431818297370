<template>
    <div>
        <div id="aliplayer"></div>
    </div>
</template>

<script>
export default {
	name: "videoPlayer",
	data() {
		return {
			// totalDuration: 0,
			// totalRemain: 0,
			players: null,

			playData: {}
		}
	},
	props: {
		videoData: {
			default: () => { },
			type: Object
		}
	},
	mounted() {
		// this.playerInit()
	},

	methods: {
		playerInit() {
			let config = {
				id: 'aliplayer',
				videoWidth: '100%',
				autoplay: true,
				vid: this.playData.videoId,
				playauth: this.playData.playAuth,
				cover: this.playData.videoImg,
				preload: true,
				"useH5Prism": true,
				encryptType: 1, // 加密类型
				"skinLayout": [
					{
						"name": "bigPlayButton",
						"align": "blabs",
						"x": '46.5%',
						"y": '45%'
					},
					{
						"name": "H5Loading",
						"align": "cc"
					},
					{
						"name": "infoDisplay"
					},
					{
						"name": "controlBar",
						"align": "blabs",
						"x": 0,
						"y": 0,
						"children": [
							{
								"name": "progress",
								"align": "blabs",
								"x": 0,
								"y": 44
							},
							{
								"name": "playButton",
								"align": "tl",
								"x": 15,
								"y": 12
							},
							{
								"name": "timeDisplay",
								"align": "tl",
								"x": 10,
								"y": 7
							},
							{
								"name": "fullScreenButton",
								"align": "tr",
								"x": 10,
								"y": 12
							},
							{
								"name": "setting",
								"align": "tr",
								"x": 15,
								"y": 12
							},
							{
								"name": "volume",
								"align": "tr",
								"x": 5,
								"y": 10
							}
						]
					}
				]
			}

			this.players = new window.Aliplayer(config, (player) => {
				// player.on('play', () => { this.onPlayerPlay(player) })//开始播放
				// player.on('pause', () => { this.onPlayerPause(player) })//停止
				// player.on('ended', () => { this.onPlayerEnded(player) })//播放结束
				player.on('error', () => this.$message.error('视频播放错误'))//播放错误
				player.on('m3u8Retry', () => this.$message.error('m3u8Retry视频播放错误'))//m3u8请求错误
			})
		},
		clear() {
			this.players.dispose()
		}
		// onPlayerPlay(player) {
		// 	this.totalDuration = player.getDuration()
		// 	this.totalRemain = player.getCurrentTime()
		// },
		// playEnd() {
		// 	this.$emit('playEnd')
		// }
		// onPlayerPause(player) {
		// 	this.totalDuration = player.getDuration()
		// 	this.totalRemain = player.getCurrentTime()
		// },
		// onPlayerEnded(player) {
		// 	this.playEnd()
		// 	this.totalDuration = player.getDuration()
		// 	this.totalRemain = player.getCurrentTime()
		// }

	},
	watch: {
		videoData: {
			handler(n) {
				this.playData = n
				// this.playerInit()
			},
			deep: true
		}
	}
}
</script>

<style lang="less" scoped>
#aliplayer {
    width: 960px !important;
    height: 660px !important;
}
</style>