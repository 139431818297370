<template>
    <!-- 视频上传 -->
    <div id="videoUpload">
        <el-upload ref="upload" class="11111" :class="{'el-upload-dragger-hide': fileList.length>=3}" drag multiple :before-upload="videoBeforeUpload" :disabled="uploadDisabled" :limit="3" :file-list="fileList" :http-request="submitForm" :on-error="videoUploadError" :on-change="fileChange" :auto-upload="true" list-type="picture-card">
            <template #default>
                <i class="el-icon-upload"></i>
                <p class="el-upload__text fs_12">{{ uploadDisabled?'视频上传中':'点击上传' }}</p>
            </template>

            <template #file="{file}">
                <div class="progress" v-if="uploading && videoId==file.videoId">
                    <el-progress type="circle" width="106" class="flex_cen" :percentage="videoUploadPercent"></el-progress>
                </div>
                <p class="fs_12 txt tip flex_cen">视频封面</p>
                <el-image class="el-upload-list__item-thumbnail" :src="file.url" lazy v-if="file.url" fit="cover" alt="视频封面" />

                <div class="el-upload-list__item-actions flex_cen" v-if="file.playAuth">
                    <p class="del-btn flex_col btn" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                        <span class="fs_14">删除</span>
                    </p>
                    <p class="del-btn flex_col btn" @click="playVideo(file)">
                        <i class="el-icon-video-play fs_22"></i>
                        <span class="fs_14">播放</span>
                    </p>
                </div>

                <div class="coverLoading flex_cen center fs_12" v-if="!uploading">
                    <p v-if="!file.url"><i class="el-icon-loading fs_14"></i> 正在提取视频封面</p>
                    <div v-if="file.url && !file.playAuth">
                        <p><i class="el-icon-loading fs_14"></i> 视频处理中，请稍后</p>
                        <p class="fs_12" v-if="file.videoDisposeTime>0">预计等待 {{ file.videoDisposeTime }} 秒</p>
                    </div>
                </div>
            </template>
        </el-upload>
        <el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" destroy-on-close width="1000px" top="10vh">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">视频预览</p>
                </div>
            </template>
            <div class="center content">
                <video-player :videoData="videoData" ref="videoPlayer"></video-player>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import videoPlayer from './videoPlayer.vue'
export default {
	name: "videoUploadCompont",
	components: { videoPlayer },
	data() {
		return {
			dialogVisible: false,
			uploading: false, // 显示上传进度
			videoUploadPercent: 0, // 上传进度
			file: null, // 本地文件
			uploader: null,

			videoData: {}, // 视频播放数据

			videoId: '',
			uploadAuth: '', // 视频上传凭证
			uploadAddress: '', // 上传地址
			fileList: [],
			videoDisposeTime: 0, // 预计视频处理时间
			showSuccessTost: true, // 视频上传成功提示
			clear: false,
			coverImgTimer: null, // 获取封面 轮询
			checkCoverTimer: null, // 确认封面截取状态 轮询
			playAuthTimer: null // 获取播放凭证 轮询
		}
	},
	computed: {
		uploadDisabled() {
			let bool = false
			this.fileList.forEach(item=>{
				if(!item.playAuth) {
					bool =  true
				}
			})
			return bool
		}
	},
	unmounted() {
		this.showSuccessTost = false
		this.clear = true
		this.uploader && this.uploader.cleanList()
	},
	methods: {
		aliVideoUpload(action, videoId, callback) {
			this.$axios({
				url: '/ent/v3/job/vod',
				hideLoading: true,
				data: {
					action,
					fileName: this.file&&this.file.name || '',
					title: '工作现场视频',
					videoId
				}
			}).then(res => {
				if (res.code == 200) {
					callback && callback(res.data)
				}
			})
		},
		handleRemove(file) {
			this.fileList.forEach((item, index) => {
				if (item.id === file.id) {
					this.fileList.splice(index, 1)
				}
			})
			this.$emit('videoIds', this.fileList)
		},
		playVideo(file) {
			this.aliVideoUpload('getPlayAuth', file.videoId, (data) => {
				if(JSON.stringify(data) == '[]') {
					this.$message.error("网络繁忙，请稍后再试")
					return
				}
				this.dialogVisible = true
				file.playAuth = data.PlayAuth
				this.$nextTick(()=>{
					this.$refs.videoPlayer.playData = file
					this.$refs.videoPlayer.playerInit()
				})
			})
		},
		close() {
			this.$refs.videoPlayer.clear()
			this.dialogVisible = false
		},
		// 获取视频封面
		getCover() {
			if(this.clear)return
			this.fileList.forEach((item, index)=>{
				if(!item.url) {
					this.aliVideoUpload('uploadComplete', item.videoId, data=>{
						if(data.CoverURL) {
							this.fileList[index].url = data.CoverURL
							this.$emit('videoIds', this.fileList)
						}else {
							setTimeout(()=>{
								this.getCover()
							}, 1000)
						}
					})
				}
			})
		},
		// 查询视频状态，获取播放凭证
		queryVideoStatus() {
			if(this.clear)return
			this.fileList.forEach((item, index)=>{
				// 确定封面提取状态
				if(!item.url) {
					// console.log('封面提取未完成！')
					setTimeout(()=>{
						this.queryVideoStatus()
					}, 1000)
					return
				}
				if(!item.playAuth) {
					this.aliVideoUpload('getPlayAuth', item.videoId, (data) => {
						if(JSON.stringify(data) == '[]') {
							setTimeout(()=>{
								this.queryVideoStatus()
							}, 1000)
							return
						}
						this.fileList[index].playAuth = data.PlayAuth
						this.$emit('videoIds', this.fileList)
						this.showSuccessTost && this.$message.success("视频上传成功")
					})
				}
			})
		},
		// 视频上传
		videoBeforeUpload(file) {
			if ([ "video/mp4", "video/ogg", "video/flv", "video/avi", "video/wmv", "video/rmvb" ].indexOf(file.type) == -1) {
				this.$message.error("请上传正确的视频格式")
				return false
			}
			// console.log(file)
			// this.file = file
			let size = file.size / 1024 / 1024
			if (size > 100) {
				this.$message.warning('视频大小不能超过 100M')
				// this.$refs.upload.clearFiles()
				return false
			}
			this.videoDisposeTime = Math.ceil(size/2)

		},
		videoUploadError() {
			this.uploading = false
			this.$message.error("视频上传服务器出错，请重新上传！")
		},
		submitForm() {
			this.aliVideoUpload('CreateUploadVideo', '', (data) => {
				let { VideoId, UploadAuth, UploadAddress } = data
				this.videoId = VideoId
				this.uploadAuth = UploadAuth
				this.uploadAddress = UploadAddress

				this.fileList.push({ url: '', videoId: VideoId, videoDisposeTime: this.videoDisposeTime, playAuth: '', id: this.fileList.length + 1 })
				this.authUpload()
				this.$emit('videoIds', this.fileList)
			})
		},
		fileChange(file) {
			console.log(file)
			if (!file.raw) {
				this.$message.error("请先选择需要上传的视频！")
				return
			}
			this.file = file.raw
			// let size = file.size / 1024 / 1024
			// if (size > 1) {
			// 	this.$message.warning('视频大小不能超过 100M')
			// 	// this.$refs.upload.clearFiles()
			// 	return false
			// }
			// this.videoDisposeTime = Math.ceil(size/2)
			var userData = '{"Vod":{}}'
			if (this.uploader) {
				this.uploader.stopUpload()
				this.videoUploadPercent = 0
			}
			this.uploader = this.createUploader()
			this.uploader.addFile(this.file, null, null, null, userData)
		},
		authUpload() {
			// 然后调用 startUpload 方法, 开始上传
			if (this.uploader !== null) {
				this.uploader.startUpload()
			}
		},
		createUploader() {
			let that = this
			let uploader = new AliyunUpload.Vod({
				timeout: 60000, // 请求超时
				partSize: 1048576, // 分片大小
				parallel: 5, // 上传分片数
				retryCount: 3, // 网络失败重试次数
				retryDuration: 2, // 网络失败重试间隔
				region: 'cn-shanghai',
				userId: '1773339594086034', // 用户id
				// 添加文件成功
				addFileSuccess: function () {
				},
				// 开始上传
				onUploadstarted: function (uploadInfo) {
					uploader.setUploadAuthAndAddress(uploadInfo, that.uploadAuth, that.uploadAddress, that.videoId)
					that.uploading = true
				},
				// 文件上传成功
				// onUploadSucceed: function (uploadInfo) {
				// },
				// 文件上传失败
				onUploadFailed: function () {
					that.$message.success("视频上传出错，请重新上传！err:500")
					that.uploading = false
				},
				// 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
				onUploadProgress: function (uploadInfo, totalSize, progress) {
					let num = Math.ceil(progress * 100)
					that.videoUploadPercent = num
				},
				// 上传凭证超时
				onUploadTokenExpired: function () {
					that.$message.warning("视频上传超时，请重新上传！")
					that.uploading = false
				},
				// 文件暂停上传
				// onUploadCanceled: function () {
				// },
				// 全部文件上传结束
				onUploadEnd: function () {
					that.uploading = false
					// that.$message.success("视频处理中，请稍后...")
					that.getCover()
					that.queryVideoStatus()
				}
			})
			return uploader
		}

	}
}
</script>

<style lang="less" scoped>
#videoUpload {
    .progress {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 9;
        padding: 10px;
        background: #fff;
        .el-progress {
            display: flex !important;
        }
    }
    /deep/.el-upload--picture-card {
        width: 126px;
        height: 126px;
        line-height: 126px;
        border: none;
    }
    /deep/.el-upload-list--picture-card .el-upload-list__item {
        width: 126px;
        height: 126px;
        margin-bottom: 0;
    }
    .el-upload-list__item-actions {
        z-index: 9;
        .del-btn {
            width: 98px;
            height: 84px;
            border-radius: 6px;
            justify-content: center;
            color: #d7deec;
            &:hover {
                color: #fff;
            }
        }
    }
    .tip {
        position: absolute;
        top: -3px;
        right: -10px;
        z-index: 5;
        background: #1890ff;
        width: 70px;
        height: 24px;
        border-radius: 0 0 0 8px;
        transform: scale(.8);
        letter-spacing: 2px;
    }
    .el-upload__text {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 62%;
        z-index: 6;
        line-height: 20px;
    }

    /deep/.el-upload-dragger {
        background-color: #f4f4f4;
        height: 126px;
        width: 126px;
        .el-icon-upload {
            margin: 0;
            font-size: 58px;
        }
    }
    .el-upload-dragger-hide {

        /deep/.el-upload--picture-card {
            display: none;
        }
    }
    .el-form-item__content {
        line-height: 30px;
        margin-left: 0 !important;
    }
    .coverLoading {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.1);
        z-index: 1;
        top: 0;
        color: #fff;
        left: 0;
    }
}
</style>